<template>
  <div>
    <div class="card">
      <div class="card-body">
        <form action="">
          <div class="form-row">
            <div class="col-md-4">
              <select v-model="search_data.year_id" v-validate="'required'" name="year_id" id="year_id" class="form-control form-control-sm" >
                <option :value="''">Select a year</option>
                <option v-for="(year, index) in years" :key="index"  :value="year.id">{{ year.year_number }}</option>
              </select>
            </div>
            <div class="col-md-4">
              <select v-model="search_data.month_id" v-validate="'required'" name="month_id" id="month_id" class="form-control form-control-sm" >
                <option :value="''">Select a month</option>
                <option v-for="(month, index) in months" :key="index" :value="month.id">{{ month.month_name }}</option>
              </select>
            </div>
            <div class="col-md-4">
              <a-button class="btn btn-success btn-block" :loading="btnLoading" @click.prevent="search()"><i class="fa fa-search mr-1" aria-hidden="true"></i>Search</a-button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="card">
      <a-spin :spinning="spinning" :delay="delayTime">
        <div class="card-body" style="padding-bottom: 0.11rem !important;">
          <div class="row">
            <div class="col-md-4">
              <div class="card bg-danger">
                <div class="card-body position-relative overflow-hidden my-issue-card" @click="hubAllIssue" style="cursor: pointer;">
                  <div class="font-size-30 font-weight-bold text-dark mb-n2">{{ issue }}</div>
                  <div class="text-uppercase">Issue - Raised Issue</div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card bg-success">
                <div class="card-body position-relative overflow-hidden" @click="hubAllIssue" style="cursor: pointer;">
                  <div class="font-size-30 font-weight-bold text-dark mb-n2">{{ solved }}</div>
                  <div class="text-uppercase">Issue Solved</div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card bg-warning">
                <div class="card-body position-relative overflow-hidden" @click="hubAllIssue" style="cursor: pointer;">
                  <div class="font-size-30 font-weight-bold text-dark mb-n2">{{ pending }}</div>
                  <div class="text-uppercase">Issue Pending - In working Progress</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body" style="padding-top: 0.11rem !important;padding-bottom: 0.11rem !important;">
          <h5>Weekly Rating Details: </h5>
          <div class="row">
            <div class="col-md-3" v-for="(filtered_week, index) in filtered_weeks" :key="index">
              <div class="card">
                <div class="card-body position-relative overflow-hidden my-week-card" @click="weeklyRating(search_data.year_id, search_data.month_id, filtered_week.id, index)" style="cursor: pointer;">
                  <div class="font-size-24 font-weight-bold text-dark mb-n2 text-center">
                    {{ filter_month_name.month_name }}
                    <span v-if="index + 1 == 1"> {{ index + 1 }}st Week</span>
                    <span v-if="index + 1 == 2"> {{ index + 1 }}nd Week</span>
                    <span v-if="index + 1 == 3"> {{ index + 1 }}rd Week</span>
                    <span v-if="index + 1 == 4"> {{ index + 1 }}th Week</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body" style="padding-top: 0.11rem !important;padding-bottom: 0.11rem !important;">
          <div class="table-responsive">
            <table class="table table-sm table-bordered text-center" id="hub_admin_list">
              <thead>
              <tr>
                <th></th>
                <th></th>
                <th colspan="4" style="background-color: #e0e0e0">Weekly Status</th>
                <th style="background-color: #c9cdf1">Monthly Status</th>
              </tr>
              <tr>
                <th>SL</th>
                <th>Accessory Name</th>
                <th>1st</th>
                <th>2nd</th>
                <th>3rd</th>
                <th>4th</th>
                <th>Individual Status</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(accessory, index) in accessories" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ accessory.name }}</td>
                <td v-for="(weekly_rating, i) in accessory.weekly_ratings" :key="i">{{ weekly_rating.average_rating }}</td>
                <td>{{ accessory.total_monthly_ratings }}</td>
              </tr>
              <tr style="font-weight: 600">
                <td colspan="2"></td>
                <td style="background-color: #f5222e14">{{ total_monthly_rating_average ? total_monthly_rating_average[0] : 0 }}</td>
                <td style="background-color: #f5222e14">{{ total_monthly_rating_average ? total_monthly_rating_average[1] : 0 }}</td>
                <td style="background-color: #f5222e14">{{ total_monthly_rating_average ? total_monthly_rating_average[2] : 0 }}</td>
                <td style="background-color: #f5222e14">{{ total_monthly_rating_average ? total_monthly_rating_average[3] : 0 }}</td>
                <td style="background-color: #229af521">{{ total_monthly_accessory_rating }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </a-spin>
    </div>
    <weekly-rating-details-modal
      :weekly_ratings="weekly_ratings"
    ></weekly-rating-details-modal>
  </div>
</template>

<script>
import apiClient from '@/services/axios'
import weeklyRatingDetailsModal from '@/views/adminScenario/issue/partial/weekly_rating_details_modal'

export default {
  name: 'update',
  components: { weeklyRatingDetailsModal },
  data() {
    return {
      issue: 0,
      pending: 0,
      solved: 0,
      total_monthly_accessory_rating: 0,
      accessories: [],
      years: [],
      months: [],
      weeks: [],
      weekly_ratings: [],
      filter_weeks: [],
      total_monthly_rating_average: [],
      btnLoading: false,
      loading: false,
      spinning: false,
      delayTime: 500,
      search_data: {
        year_id: '',
        month_id: parseInt(new Date().getMonth()) + 1,
      },
    }
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    filtered_weeks() {
      if (this.search_data.month_id) {
        return this.weeks.filter(week => week.month_id === this.search_data.month_id)
      }
    },
    // eslint-disable-next-line vue/return-in-computed-property
    filter_month_name() {
      if (this.search_data.month_id) {
        return this.months.find(month => month.id === this.search_data.month_id)
      }
    },
  },
  mounted() {
    this.getCodes()
  },
  methods: {
    getCodes() {
      apiClient.get('api/admin-scenario/getCodes')
        .then(response => {
          this.years = response.data.years
          this.months = response.data.months
          this.weeks = response.data.weeks
          const year = this.years.find((year) => year.year_number === new Date().getFullYear())
          this.search_data.year_id = year.id
          setTimeout(() => {
            this.search()
          }, 2000)
        })
        .catch(error => {
          console.log(error)
        })
    },
    search() {
      this.spinning = true
      this.loading = true
      this.btnLoading = true
      apiClient.post('api/admin-scenario/issue/hub/details/search', this.search_data)
        .then(response => {
          this.spinning = false
          this.loading = false
          this.btnLoading = false
          if (response.data.error === true) {
            this.$notification.error({
              message: response.data.message,
            })
          } else {
            this.issue = response.data.issue
            this.pending = response.data.pending
            this.solved = response.data.solved
            this.accessories = response.data.accessories
            this.total_monthly_accessory_rating = response.data.total_monthly_accessory_rating
            this.total_monthly_rating_average = response.data.total_monthly_rating_average
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    hubAllIssue() {
      this.$router.push({ name: 'hubAllIssue' })
    },
    weeklyRating(yearId, monthId, weekId, index) {
      this.weekly_ratings = {}
      apiClient.get('api/admin-scenario/hub/weekly/rating/' + yearId + '/' + monthId + '/' + weekId).then(response => {
        if (!response.data.error) {
          this.weekly_ratings = response.data.weekly_ratings
          this.$bvModal.show('admin-scenario-weekly-rating-modal')
        }
      })
    },
  },
}
</script>

<style scoped>
.my-week-card:hover {
  border: 1px solid #3E7DC0 !important;
}
.my-issue-card:hover {
  border: 1px solid #3E7DC0 !important;
}
</style>
